import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {GettingStartedBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {AskHowToInfoAlert} from "../../components/alert";
import {CodeSnippet} from "../../components/snippet";
import {KeyHeader} from "../../components/typography";
import {ApiLink} from "../../components/link";

const pageTitle = "Work with Date/Time";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>Working with date/time includes the following:</p>
        <ul>
            <li>
                {' '}<a href="#binding_values">Binding date/time values</a>{' '} into input variables (SQL
                statements) or input parameters (stored procedures/functions)
            </li>
            <li>
                {' '}<a href="#reading_values">Reading date/time values</a>{' '} (from fields in result set or from
                output parameters of stored procedure)
            </li>
        </ul>

        <KeyHeader id="binding_values">Binding date/time values</KeyHeader>
        <p>
            Let's say we want to update date/time field named {' '}<code>FDATETIME</code>{' '}
            from table named {' '}<code>TEST</code>{' '} with the value of
            {' '}<code>October 6, 2000 19:00:00</code>{' '}.
        </p>
        <p>
            First, let's create a sample command that requires a datetime binding:
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &con, 
    _TSA("Update TEST set FDATETIME = :1"));
            `.trim()}
        </CodeSnippet>

        <p>
            You need an instance of {' '}<ApiLink>SADateTime</ApiLink>{' '} class to do the bind. There are several
            ways to create it. First is to construct it explicitly:
        </p>
        <CodeSnippet>{`
SADateTime dtValue(
    2000 /*year*/, 10 /*October*/, 6 /*day of month*/,
    19 /*hours*/, 0 /*minutes*/, 0 /*seconds*/);
            `.trim()}
        </CodeSnippet>

        <p>
            Alternatively, you can convert to {' '}<ApiLink>SADateTime</ApiLink>{' '} from an existing C struct tm:
        </p>
        <CodeSnippet>{`
extern struct tm some_tm_Value;
SADateTime dtValue = some_tm_Value;
            `.trim()}
        </CodeSnippet>

        <p>
            Or you can also convert from an instance of Windows DATE data type:
        </p>
        <CodeSnippet>{`
extern DATE some_DATE_Value;
SADateTime dtValue = some_DATE_Value;
            `.trim()}
        </CodeSnippet>

        <p>
            Finally, bind the date/time value and execute:
        </p>
        <CodeSnippet>{`
cmd << dtValue;
cmd.Execute();
        `.trim()}
        </CodeSnippet>

        <KeyHeader id="reading_values">Reading date/time values</KeyHeader>
        <p>
            Let's say we want to retrieve date/time field named {' '}<code>FDATETIME</code>{' '}
            from table named {' '}<code>TEST</code>{' '}.
        </p>
        <p>
            Let's create and execute a sample SQL command that returns data of datetime type
            (for more information see {' '}<Link to="/HowTo/commands/">Execute SQL Command</Link>):
        </p>
        <CodeSnippet>{`
SACommand cmd(
    &con, 
    _TSA("SELECT FDATETIME FROM TEST"));
cmd.Execute();
            `.trim()}
        </CodeSnippet>


        <p>
            Now we can fetch the data and access date/time column values using {' '}<ApiLink>SADateTime</ApiLink>,
            standard C struct tm or Windows DATE data type:
        </p>
        <CodeSnippet>{`
while(cmd.FetchNext())
{
    SADateTime dtValue = cmd.Field(_TSA("FDATETIME"));
    // access through standard C struct tm
    struct tm tmValue = dtValue;
    struct tm tmValue2 = (struct tm)cmd.Field(_TSA("FDATETIME")).asDateTime();
    ...
    // access through Windows DATE data type
    DATE dateValue = dtValue;
    DATE dateValue2 = (DATE)cmd.Field(_TSA("FDATETIME")).asDateTime();
    ...
}`.trim()}
        </CodeSnippet>

        <AskHowToInfoAlert/>
    </Layout>
);

export default Page;
